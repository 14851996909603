<template>
    <div class="creator-agreement">
        <p>骑驴是一个供广大用户探索奇妙世界的平台。建设美好骑驴需要平台和用户的共同努力，让我们一起维护有利于平台和谐发展、健康的网络环境，营造风清气正的社区生态。</p>
        <p>骑驴平台提倡所有用户（创作者）在骑驴网络社区以成熟负责的态度发布内容和信息，以自己希望被善待的方式善待他人，以理性平和的方式对待不同的声音，以积极向上的心态共同守望相助，共同维护良好的社区氛围，和骑驴一起选择乐观。</p>

        <h3>一、骑驴鼓励以下内容，优质内容会得到更多推荐</h3>
        <dl>
            <div>
                <dt>内容原创：</dt>
                <dd>鼓励个人原创内容，搬运作品、盗用他人作品等行为将导致作品不被推荐、限期封禁或永久封号等处罚；</dd>
            </div>
            <div>
                <dt>内容优质：</dt>
                <dd>鼓励画风清晰、完整度高和可看性强的作品，建议减少拼接网络图片、粗劣特效、无实质性内容、仅文字堆砌/滚动的作品；</dd>
            </div>
            <div>
                <dt>内容真实正向：</dt>
                <dd>倡导积极正向的作品，鼓励真人出镜或讲解，避免虚假做作、卖惨博人眼球的行为，提倡记录美好生活，表达真实的自己。</dd>
            </div>
        </dl>

        <h3>二、追求真实有价值，但骑驴有义务进行内容管理</h3>
        <h4>（一）骑驴平台禁止发布和传播下列内容：</h4>
        <ol class="bracket-alpha">
            <li>反对宪法所确定的基本原则；</li>
            <li>危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一；</li>
            <li>损害国家荣誉和利益；</li>
            <li>宣扬恐怖主义、极端主义；</li>
            <li>煽动民族仇恨、民族歧视，破坏民族团结；</li>
            <li>破坏国家宗教政策，宣扬邪教和封建迷信；</li>
            <li>散布谣言，扰乱社会秩序、破坏社会稳定；</li>
            <li>散布淫秽、色情、赌博、暴力、凶杀、恐怖内容或者教唆犯罪；</li>
            <li>含有法律、行政法规禁止的其他内容；</li>
            <li>美化侵略者和侵略战争，亵渎英雄烈士；</li>
            <li>传授犯罪方法或宣扬美化犯罪分子和犯罪行为；</li>
            <li>含有涉毒、竞逐等危险驾驶、欺凌等违反治安管理的内容；</li>
            <li>侮辱或者诽谤他人，侵害他人合法权益；</li>
            <li>违法开展募捐活动；</li>
            <li>发布违法网络结社活动信息和涉嫌非法社会组织的信息；</li>
            <li>未经授权使用他人商号、商标和标识；</li>
            <li>侵犯他人著作权，抄袭他人作品，未经他人允许，搬运、盗用他人作品；</li>
            <li>宣传伪科学、展示违法工具或违反科学常识的内容；</li>
            <li>展示丑陋、粗俗、下流的风俗，宣扬拜金主义和奢靡腐朽的生活方式；</li>
            <li>展示自残自杀内容或其他危险动作，以引起反感和不适或容易诱发模仿；</li>
            <li>展示不符合骑驴用户协议的商业广告或类似的商业招揽信息、过度营销信息及垃圾信息；</li>
            <li>其他违反公序良俗、危害公共安全及社会不文明现象的内容。</li>
        </ol>


        <h4>（二）骑驴会对以下内容进行限制</h4>
        <ol class="bracket-alpha">
            <li>违反社会主义核心价值观</li>
            <ul>
                <li>有损国家形象（包括但不限于国土展示不完整、歪曲历史人物及事件）；</li>
                <li>借助社会负面事件、敏感事件、革命烈士等进行商业营销宣传；</li>
                <li>诋毁民族、宗教政策，展示邪教和封建迷信内容；</li>
                <li>进行有损国家公职人员形象的行为（包括但不限于展示有损“公检法军警”等国家公职人员形象的内容；私人场景出现“公检法军警”等国家公职人员）；</li>
            </ul>
            <li>低俗色情</li>
            <ul>
                <li>内容低俗（包括但不限于文字用语低俗、情色影片、具有暗示性的文字或内容）；</li>
                <li>着装低俗（包括但不限于穿着暴露、轮廓明显、露点）；</li>
                <li>动作/行为低俗（包括但不限于性暗示行为、低俗场景、低俗舞蹈、宣扬不正当男女关系）；</li>
            </ul>
            <li>侵犯未成年人的合法权益</li>
            <ul>
                <li>未成年人不当着装/行为（包括但不限于敏感部位裸露、成人向着装、成人舞蹈）；</li>
                <li>未成年人不良导向（包括但不限于成人化行为、化妆）；</li>
                <li>未成年人危险行为（包括但不限于恶搞未成年人、家长同婴幼儿或儿童做危险动作、其他未成年人实施的可能会存在安全风险的行为）；</li>
            </ul>
            <li>危险行为</li>
            <ul>
                <li>视频中出现可能存在安全风险的玩具或其他物品；</li>
                <li>含有危险驾驶、竞逐、欺凌等违反公共治安管理行为的内容（包括但不限于驾驶或乘坐车辆未系安全带、使用暴力等）；</li>
                <li>具有安全隐患的危险动作，容易诱发模仿的内容（包括但不限于燃烧柳絮、人群中点燃爆竹、整蛊他人或潜在的危险元素，易引发人身安全风险）；</li>
            </ul>
            <li>动物不当内容</li>
            <ul>
                <li>动物低俗内容（包括但不限于动物哺乳行为、刻意展示动物交配）；</li>
                <li>虐待动物（包括但不限于用硬物砸向动物或扔垃圾等行为、敲打拉扯动物、动物互殴行为）；</li>
                <li>违法饲养保护动物、违法捕杀动物、与动物长时间接吻；</li>
            </ul>
            <li>引人不适</li>
            <ul>
                <li>视频中出现血腥暴力、密集恐惧、恐怖灵异、丧葬相关等引人不适的画面或剧情；</li>
                <li>涉及手术过程、纹身纹眉过程、或刻意展示伤口等易引人不适的内容；</li>
            </ul>
            <li>生活医疗</li>
            <ul>
                <li>内容包含无法证实/夸大宣传的医疗方法、减肥偏方；</li>
                <li>视频中可能存在金融诈骗、个人隐私泄露、医美整形等易引发人身或财产安全风险的内容；</li>
            </ul>
            <li>违法违规</li>
            <ul>
                <li>视频中存在赌博彩票、封建迷信、邪教组织、毒品、管制刀具、攻击器械等物品或相关演绎行为；</li>
                <li>视频中存在丑化、辱骂等攻击他人的行为，或曝光他人身份信息等侵犯隐私的行为；</li>
            </ul>
            <li>公序良俗、不良导向等</li>
            <ul>
                <li>视频中存在抽烟、酗酒、虐待、恶搞、歧视、炫富、卖惨、脏话、婚闹等社会不良风气或不文明行为；</li>
                <li>过度炒作明星绯闻、娱乐八卦的行为；</li>
            </ul>
            <li>商业广告或类似的商业招揽信息</li>
            <ul>
                <li>视频中含有商品展示，品牌/产品/价格等的介绍性口播，或引导购买等广告元素；</li>
                <li>视频中含有活动信息（抽奖、送礼物等）、联系方式、二维码、链接、地址、日期等元素；</li>
            </ul>
            <li>其他</li>
            <ul>
                <li>视频画质模糊，无完整内容，观感体验差；</li>
                <li>视频内容诱导他人关注点赞或评论；</li>
                <li>视频整体为搬运内容，包括但不限于：大段内容带有其他平台水印logo、画质模糊等；</li>
                <li>视频中存在其他不适合大量曝光的内容。</li>
            </ul>
        </ol>
        <h3>三、附则</h3>
        <p>本公约自公布之日起执行。</p>
    </div>
</template>

<script>
    export default {
        name: "CreatorAgreement"
    }
</script>

<style lang="less" scoped>
    .creator-agreement {
        padding:0 40px;
        p {text-indent:2em;}
        dl {
            dt {
                font-weight:600;
                width:120px;
                flex-grow:1;
                flex-shrink:0;
                text-align:right;
            }
            dd {margin:0;}
            > div {
                display:flex;
            }
            div + div {margin-top:15px;}
        }
        ul {
            padding:15px 0;
            li {padding:2px 0;}
        }
        .bracket-alpha {
            counter-reset:item;
            padding-left:70px;
            & > li {
                list-style-type:none;
                &:before {
                    content:"(" counter(item) ")";
                    counter-increment:item;
                    display:inline-block;
                    width:2.5em;
                    margin-left:-3em;
                    text-align:right;
                    padding-right:0.5em;
                }
            }
        }
    }
</style>